import PropTypes from 'prop-types';
import CustomPropTypes from 'custom-prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Pens from '~/scenes/pens';

export default function PensPage({ location: { pathname }, data: { pens } }) {
  pens = CustomPropTypes.transformAirtable(pens);

  return (
    <Pens path={pathname} pens={pens} />
  );
}

PensPage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  data: PropTypes.shape({
    pens: CustomPropTypes.airtable({
      slug: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      available: PropTypes.bool,
      type: PropTypes.string.isRequired,
      tags: PropTypes.arrayOf(PropTypes.string).isRequired,
      image: CustomPropTypes.airtableAttachments({ thumbnails: [ 'large' ] }).isRequired,
      imageAlt: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query getPens {
    pens: allAirtable(filter: { table: { eq: "pens" } }) {
      nodes {
        id
        data {
          slug
          label
          available
          type
          tags
          image {
            url
            thumbnails {
              large {
                url
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;
