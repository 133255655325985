import PropTypes from 'prop-types';
import React from 'react';

import useFilters from '~/utils/use-filters';
import Header from '~/components/header';
import Layout from '~/components/layout';
import Section from '~/components/section';

export default function Pens({ pens: allPens, ...props }) {
  const [
    pens,
    [
      [ available, setAvailable ],
      [ type, setType ],
      [ tags, setTags ],
    ],
    [ allAvailabilities, allTypes, allTags ],
  ] = useFilters(allPens, [ 'available', 'type', 'tags' ]);

  return (
    <Layout title="Handmade Pens" {...props}>
      <Section title="Handmade Pens" id="main" className="style3">
        <div className="row gtr-150">
          <div className="col-12">
            <div id="content">
              <article className="box post">
                <Header title="Examples of my work." className="style1">
                  See something you like?<br />
                  Many of these are available, and I am always open for custom orders.
                </Header>
                <div className="container">
                  <div className="row aln-center highlights">
                    <div className="col-12 center">
                      <form>
                        <select className="inline" title="Filter by Availability" value={JSON.stringify(available)} onChange={e => setAvailable(JSON.parse(e.target.value))}>
                          <option value={JSON.stringify('')}>- Show All Availability -</option>
                          {allAvailabilities.map(available => (
                            <option key={available} value={JSON.stringify(available)}>{available ? 'Available' : 'Unavailable'}</option>
                          ))}
                        </select>

                        <select className="inline" title="Filter by Type" value={JSON.stringify(type)} onChange={e => setType(JSON.parse(e.target.value))}>
                          <option value={JSON.stringify('')}>- Show All Types -</option>
                          {allTypes.map(type => (
                            <option key={type} value={JSON.stringify(type)}>{type}</option>
                          ))}
                        </select>

                        <select className="inline" title="Filter by Tags" value={JSON.stringify(tags)} onChange={e => setTags(JSON.parse(e.target.value))}>
                          <option value={JSON.stringify('')}>- Show All Tags -</option>
                          {allTags.map(tag => (
                            <option key={tag} value={JSON.stringify(tag)}>{tag}</option>
                          ))}
                        </select>
                      </form>
                    </div>
                    {pens.map(({ id, label, type, tags, image }) => (
                      <div key={id} className="col-4 col-12-medium">
                        <section className="highlight">
                          <div className="image">
                            <img src={image[0].thumbnails.large.url} alt={image[0].alt || label} />
                            <div className="hover">
                              <h3>{label}</h3>
                              <p>{type} - {tags.join(', ')}</p>
                            </div>
                          </div>
                        </section>
                      </div>
                    ))}
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </Section>
    </Layout>
  );
}

Pens.propTypes = {
  pens: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    // slug: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    // available: PropTypes.bool,
    image: PropTypes.arrayOf(PropTypes.shape({
      alt: PropTypes.string,
      url: PropTypes.string.isRequired,
      thumbnails: PropTypes.shape({
        large: PropTypes.shape({
          url: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    })).isRequired,
    type: PropTypes.string.isRequired,
    // notes: CustomPropTypes.airtableRemark(),
    tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  })).isRequired,
};
